.footer .footer-txt {
  color: #999999;
  font-size: 10px;
  padding: 10px 17px 10px 0;
  line-height: 13px;
}

.footer .footer-txt a {
  color: #999999;
  text-decoration: none;
}

.footer .footer-txt a:hover {
  color: #041a3b;
}

.footer .footer-txt span {
  display: inline-block;
  margin: 0 5px;
}

@media (min-width: 960px) {
  .footer {
    text-align: right;
  }
}
