.home .home-latest-news-wrapper {
  font-size: 11px;
}

.home .home-latest-news {
  padding: 15px;
}

.home .home-latest-news .news-date {
  position: relative;
  padding-right: 8px;
  margin-right: 8px;
}

.home .home-latest-news .news-ttl {
  color: #041a3b;
  text-decoration: none;
}

.home .home-latest-news .news-ttl:hover {
  color: #358286;
}

.home .home-latest-news-wrapper h3 {
  color: #012152;
  font-size: 14px;
  font-weight: bold;
}

.home .home-latest-news-wrapper .news-date,
.home .home-latest-news-wrapper .news-ttl {
  display: block;
}

.home .home-latest-news-wrapper .news-ttl {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.home .home-latest-news-wrapper .news-ttl {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.home
  .home-latest-news-wrapper
  .home-lates-news-item:nth-last-of-type(1)
  .news-ttl {
  border-bottom: none;
}

.home .home-latest-news-wrapper .home-latest-news-subscribe {
  position: relative;
}

.home
  .home-latest-news-wrapper
  .home-latest-news-subscribe
  .home-subscribe-ttl {
  position: absolute;
  top: 50%;
  left: 20px;
  color: #fff;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2px 20px;
}
.home
  .home-latest-news-wrapper
  .home-latest-news-subscribe
  .home-subscribe-link {
  display: block;
  text-decoration: none;
}

.home .hsl-ttl {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background-color: #358286;
  color: #fff;
}

.home .hsl-ttl span {
  position: relative;
  top: 2px;
}

.home .hsl-ttl i {
  font-size: 16px;
  margin-right: 10px;
  vertical-align: middle;
}

.home .swiper-container {
  max-width: 960px;
}

@media (min-width: 960px) {
  .home .swiper-container {
    max-width: 737px;
  }

  .home {
    background-color: #012152;
  }

  .home .home-latest-news-wrapper .home-latest-news {
    width: 395px;
  }

  .home .home-latest-news-wrapper .home-latest-news-subscribe {
    width: 295px;
  }

  .home .home-latest-news-wrapper {
    margin-left: 50px;
    background-color: #e1e1e1;
    display: flex;
  }

  .home .home-latest-news .news-date::after {
    content: "";
    position: absolute;
    border-left: 1px solid #aaa;
    height: 100%;
    top: 0;
    right: 0;
  }

  .home .home-latest-news-wrapper .news-date,
  .home .home-latest-news-wrapper .news-ttl {
    display: inline;
  }

  .home .home-latest-news-wrapper .news-ttl {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}
