.popup-sliders {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  z-index: 999;
  opacity: 0;
  transition: all 0.4s;
  overflow-y: auto;
}

.popup-sliders.active {
  opacity: 1;
  visibility: visible;
}

.popup-sliders .ps-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  height: 100%;
}

.popup-sliders .swiper-wrapper {
  height: 100vh !important;
}

.popup-sliders .ps-content .swiper-container {
  /* max-height: 100%; */
  /* width: 100%;
  max-width: 800px; */
  width: 100%;
  height: auto;
}

.popup-sliders .ps-content .swiper-container .swiper-slide {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.popup-sliders .ps-content .swiper-container .swiper-wrapper {
  display: flex;
  align-items: center;
}

.popup-sliders .popup-sliders-close {
  position: fixed;
  cursor: pointer;
  width: 23px;
  height: 23px;
  top: 20px;
  right: 20px;
  z-index: 10;
  color: #fff;
}

.popup-sliders .swiper-slide-content {
  position: absolute;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.popup-sliders .swiper-slide-top {
  width: 100%;
  padding: 10px;
  background-color: grey;
  color: #fff;
  position: relative;
  z-index: 999;
  top: 0;
  min-width: max-content;
  text-align: center;
}

.popup-sliders .ps-item-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: 100%;
}

.popup-sliders .ps-item-wrapper .ps-item-image {
  width: 100%;
}
.popup-sliders .ps-item-wrapper .ps-item-image.portrait {
  width: auto;
  height: calc(100vh - 38px);
}

.popup-sliders .ps-item-wrapper.portrait {
  width: auto;
}

@media (orientation: landscape) {
  .popup-sliders .ps-item-wrapper .ps-item-image.portrait {
    width: auto;
    height: calc(100vh - 38px);
  }

  .popup-sliders .ps-item-wrapper.portrait {
    width: auto;
  }
}

@media (orientation: portrait) and (max-width: 800px) {
  .popup-sliders .ps-item-wrapper .ps-item-image.portrait {
    width: auto;
    height: 60vh;
  }

  .popup-sliders .ps-item-wrapper.portrait {
    width: auto;
  }
}

@media (orientation: landscape) and (max-height: 500px) {
  .popup-sliders .ps-item-wrapper.landscape {
    width: auto;
  }

  .popup-sliders .ps-item-wrapper .ps-item-image.landscape {
    width: auto;
    height: 100vh;
  }
}
