.sale-item {
  background-color: #e1e1e1;
}

.sale .sale-item {
  padding: 20px;
}

.sale .sale-item .s-name {
  font-size: 13px;
  color: #041a3b;
}

.sale .sale-item .s-address {
  font-size: 11px;
  color: #333333;
  padding-bottom: 12px;
}

.sale .sale-item:nth-of-type(odd) {
  background-color: #d0d0d0;
}

.sale .sale-property-portfolio-link a {
  text-align: center;
}

.sale .sale-property-portfolio-link a {
  display: inline-block;
  background-color: #888;
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.sale .sale-property-portfolio-link {
  text-align: center;
}

.sale .sale-property-portfolio-link i {
  margin-left: 10px;
  line-height: 1;
  font-size: 18px;
  vertical-align: middle;
}

.sale .sale-property-portfolio-link span {
  vertical-align: middle;
}

.sale .sale-item .sale-content-wapper {
  display: none;
}

.sale .sale-trigger {
  position: absolute;
  cursor: pointer;
  right: 20px;
}

/* .sale .sale-item.active .sale-content-wapper {
  visibility: visible;
  max-height: 100%;
} */

.sale .sale-header {
  display: none;
}

.sale .sale-table {
  margin-top: 20px;
}

.sale .sale-table .sale-row {
  padding: 20px;
  background-color: #b5b5b5;
  margin-bottom: 20px;
}

.sale .mobile-leasing-ttl {
  display: inline-block;
  margin-right: 20px;
  width: 180px;
}

.sale .sale-item-floor {
  display: flex;
  margin-top: 20px;
}

.sale .sale-item-floor .sale-show-more-detail {
  background-color: #999;
  color: #fff;
  padding: 5px 20px;
  margin-right: 20px;
  color: #041a3b;
}

.sale .sale-show-more-detail {
  color: #041a3b;
}

.sale .sale-show-more-detail:hover {
  color: #358286;
}

.sale .only-for-desktop {
  display: none;
}

.sale .sale-item-floor i {
  font-size: 30px;
  cursor: pointer;
  margin-right: 20px;
  line-height: 0;
  top: 14px;
  position: relative;
}

.sale .sale-trigger {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}

.sale .sale-trigger::after {
  content: "add";
  font-family: "Material Icons";
  display: block;
  font-size: 24px;
  line-height: 1;
}

.sale .sale-trigger.active::after {
  content: "remove";
}

.sale .sale-table-footer {
  padding: 10px;
  background-color: #8a8a8a;
}

.sale .stf-btn {
  display: inline-block;
  background-color: #041a3b;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.sale .stf-btn i {
  font-size: 12px !important;
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.sale .sale-remarks {
  width: 200px;
}

.sale .mobile-btn-wrapper {
  background-color: #999;
  color: #fff;
  padding: 5px 10px;
  margin-right: 20px;
}

.sale .mobile-btn-wrapper img {
  position: relative;
  top: -2px;
}

@media (min-width: 960px) {
  .sale .sale-table i {
    font-size: 21px;
  }
  .sale .mobile-leasing-ttl {
    display: none;
  }

  .sale .sale-item {
    padding: 38px 0 43px 45px;
  }

  .sale .sale-item .li-name {
    padding-bottom: 14px;
  }

  .sale .sale-table {
    display: table;
    width: 100%;
    background-color: #b5b5b5;
  }

  .sale .sale-table .sale-header > div {
    border-bottom: 1px solid #fff;
  }

  .sale .sale-row {
    display: table-row;
  }

  .sale .sale-row > div {
    display: table-cell;
    padding: 4px;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
  }

  .sale .sale-item .sale-content-wapper {
    display: block;
  }

  .sale .sale-row > div.sale-item-floor {
    display: none;
  }

  .sale .sale-property-portfolio-link {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .sale .sale-trigger {
    display: none;
  }

  .sale .sale-item .sale-content-wapper {
    display: block !important;
  }

  .sale .only-for-desktop {
    display: table-cell;
  }
  .sale .sale-property-portfolio-link a {
    display: inline;
  }
}
