.sliders {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  z-index: 999;
  opacity: 0;
  transition: all 0.4s;
  overflow-y: auto;
}

.sliders.active {
  opacity: 1;
  visibility: visible;
}

.sliders .s-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  height: 100%;
}

.sliders .swiper-wrapper {
  height: 100vh !important;
}

.sliders .s-content .swiper-container {
  /* max-height: 100%; */
  /* width: 100%;
  max-width: 800px; */
  width: 100%;
  height: auto;
}

.sliders .s-content .swiper-container .swiper-slide {
  display: flex;
  height: 100%;
}

.sliders .s-content .swiper-container .swiper-wrapper {
  display: flex;
  align-items: center;
}

.sliders .sliders-close {
  position: fixed;
  cursor: pointer;
  width: 23px;
  height: 23px;
  top: 20px;
  right: 20px;
  z-index: 10;
  color: #fff;
}

.sliders .swiper-slide-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.sliders .swiper-slide-content .swiper-slide-top {
  width: 100%;
  height: 60px;
  color: #fff;
}
