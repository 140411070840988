@import "styles/app.css";
@import "styles/header.css";
@import "styles/spinner.css";
@import "styles/home.css";
@import "styles/website-menu.css";
@import "styles/page-title.css";
@import "styles/footer.css";
@import "styles/disclaimer.css";
@import "styles/sitemap.css";
@import "styles/individual.css";
@import "styles/staff.css";
@import "styles/property-portfolio.css";
@import "styles/light-box.css";
@import "styles/leasing.css";
@import "styles/sliders.css";
@import "styles/popup.css";
@import "styles/sale.css";
@import "styles/news.css";
@import "styles/contact-us.css";
@import "styles/subscribe.css";
@import "styles/ckeditor.css";
@import "styles/independent.css";
@import "styles/mobile-menu.css";
@import "styles/popup-sliders.css";
@import "styles/custom-light-box.css";

.video-scene {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  transition: all 0.4s;
  overflow-y: auto;
}

.video-scene .video-scene-close {
  position: fixed;
  cursor: pointer;
  width: 23px;
  height: 23px;
  top: 20px;
  right: 20px;
  z-index: 10;
  color: #fff;
}

.video-scene .video-scene-content {
  max-width: 768px;
  margin: auto;
  padding: 30px;
}
