.sitemap {
  background-color: #e1e1e1;
}
.sitemap .sitemap-content {
  padding: 41px 30px 41px 41px;
  width: 393px;
  list-style: none;
}

.sitemap .sitemap-content ul {
  list-style: none;
  padding-left: 10px;
}

.sitemap .sitemap-content .sitemap_firstlevel {
  margin-bottom: 10px;
}

.sitemap .sitemap-content .sitemap_firstlevel > a {
  font-size: 12px;
  line-height: 15px;
  color: #041a3b;
  font-weight: bold;
}

.sitemap .sitemap_firstlevel ul a {
  font-size: 11px;
  color: #666666;
  line-height: 15px;
  text-decoration: none;
}
