.page-title {
  background-color: #002152;
  width: 100%;
  height: 60px;
  color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.page-title h1 {
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 5px;
}

.page-title h3 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

@media (min-width: 960px) {
  .page-title {
    height: 90px;
  }
}
