.individual {
  background-color: #d0d0d0;
}

.individual .individual-banner {
  width: 100%;
}

.individual .i-content {
  padding: 20px;
}

.individual .i-contetn-image {
  text-align: center;
}

@media (min-width: 960px) {
  .individual .i-content-wrapper {
    display: flex;
  }

  .individual .individual-banner {
    margin-top: -70px;
  }

  .individual .i-content {
    padding: 41px 16px 50px 41px;
    max-width: 387px;
    box-sizing: content-box;
  }

  .individual .i-contetn-image {
    width: 352px;
    margin-top: 20px;
  }
}
