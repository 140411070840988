.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  z-index: 999;
  opacity: 0;
  transition: all 0.4s;
  overflow-y: auto;
}

.popup.active {
  opacity: 1;
  visibility: visible;
}

.popup .p-content {
  position: absolute;
  top: 40px;
  left: 50%;
  width: 90%;
  max-width: 768px;
  transform: translateX(-50%);
  background-color: #fff;
  overflow-y: auto;
  margin: 20px 0;
}

.popup .p-close {
  position: fixed;
  cursor: pointer;
  width: 23px;
  height: 23px;
  top: 20px;
  right: 20px;
  z-index: 10;
  color: #fff;
}

.popup .p-content .p-content-ttl {
  /*   display: inline-block; */
  max-width: 210px;
  min-width: 210px;
}

.popup .p-content ul {
  padding: 15px;
}

.popup .popup-contact-us-link {
  border-top: 1px solid #fff;
  padding: 6px 0 6px 9px;
  text-align: center;
}

.popup .popup-contact-us-link .link {
  background-color: #002152;
  color: #fff;
  font-size: 11px;
  padding: 4px 10px;
  text-decoration: none;
  cursor: pointer;
}

.popup .popup-contact-us-link i {
  font-size: 10px;
  vertical-align: middle;
  margin-right: 10px;
}

.popup .p-content-top {
  background: #eee;
  padding: 20px;
}

.popup .p-content-floor {
  padding: 20px;
}
