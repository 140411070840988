.disclaimer {
  background-color: #e1e1e1;
}

.disclaimer .disclaimer-content {
  padding: 41px 30px 41px 41px;
  max-width: 464px;
  box-sizing: content-box;
}

.disclaimer .disclaimer-link:hover {
  color: #358286;
}

.disclaimer .disclaimer-link {
  color: #041a3b;
  text-decoration: underline;
}
