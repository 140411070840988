.contact-us {
  background-color: #d0d0d0;
}

.contact-us .cu-form {
  width: 100%;
}

.contact-us .cu-info {
  width: 100%;
}

.contact-us .cu-form-ttl {
  flex: 0 0 100%;
}

.contact-us .cu-form-txt {
  flex: 0 0 100%;
}

.contact-us .cu-btn {
  background-color: #002152;
  color: #fff;
  font-size: 11px;
}

.contact-us .contact-us-ttl {
  font-size: 12px;
  line-height: 15px;
  color: #041a3b;
  font-weight: bold;
}

.contact-us .cu-info-title {
  color: #041a3b;
  font-size: 14px;
}

@media (min-width: 960px) {
  .contact-us .cu-form-ttl {
    flex: 0 0 30%;
  }

  .contact-us .cu-form-txt {
    flex: 0 0 70%;
  }

  .contact-us .cu-form {
    padding: 42px 20px 50px 41px;
    width: 444px;
  }

  .contact-us .cu-info {
    background-color: #e1e1e1;
    width: 295px;
    padding: 20px;
    padding-top: 40px;
  }
}
