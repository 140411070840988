.header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 2px 10px #ddd;
  z-index: 90;
}

.header .logo {
  width: 60px;
}

.header .mobile-trigger {
  position: relative;
  margin-right: 20px;
  top: 10px;
}

.header .header-left {
  margin-left: 20px;
  text-align: left;
}

.header .header-right {
  color: #999999;
}

.header .header-right span.active,
.header .header-right span:hover {
  color: #001c54;
}

.header .header-right span:nth-last-of-type(1) {
  padding-right: 32px;
}

body.language-tc,
.language-tc {
  /* font-family: "Noto Sans TC", sans-serif; */
  font-family: Arial, Geneva, Helvetica, sans-serif;
}

body.language-en,
.language-en {
  font-family: Arial, Geneva, Helvetica, sans-serif;
}

.mb-slogan {
  display: block;
  margin-top: 5px;
}

@media (min-width: 960px) {
  .mb-slogan {
    display: none;
  }
  .header .header-left {
    width: 221px;
    padding-right: 38px;
    text-align: right;
  }

  .header .logo {
    width: 99px;
  }

  .header {
    position: relative;
    padding: 19px 0;
    font-size: 12px;
    box-shadow: none;
    z-index: 0;
  }
  .header .mobile-trigger {
    display: none;
  }
}
