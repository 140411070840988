.staff-wrapper {
  padding-left: 41px;
}

.staff {
  background-color: #b3b3b3;
  margin-bottom: 20px;
}

.staff .staff-name {
  color: #041a3b;
  font-size: 12px;
  font-weight: normal;
  padding-bottom: 10px;
}

.staff .staff-name {
  color: #041a3b;
  font-size: 12px;
  font-weight: normal;
  padding-bottom: 0;
  margin-bottom: 0;
}

.staff .staff-name:nth-of-type(2) {
  padding-bottom: 10px;
}

.staff .staff-title {
  color: #041a3b;
}
