.news {
  background-color: #d0d0d0;
}

.news .news-detail {
  display: none;
}

.news .news-list {
  width: 100%;
}

.news .news-item {
  font-size: 11px;
  display: block;
  color: #032552;
  border-bottom: 1px solid #c6c6c6;
  background-color: #e1e1e1;
  padding: 10px 10px 10px 10px;
  line-height: 14px;
  cursor: pointer;
}

.news .news-ttl {
  font-size: 12px;
  color: #032552;
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 10px;
}

.news .news-btn {
  font-size: 14px;
  position: relative;
  top: 3px;
}

.news .news-mb-content {
  display: none;
  margin-top: 20px;
}

.news .news-mb-trigger {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}

.news .news-mb-trigger::after {
  content: "add";
  font-family: "Material Icons";
  display: block;
  font-size: 24px;
}

.news .news-mb-trigger.active::after {
  content: "remove";
}

.news .news-item-header {
  position: relative;
  padding-right: 40px;
}

.news .news-item-header .news-item-header-ttl {
  margin-top: 5px;
  color: #666666;
}

@media (min-width: 960px) {
  .news .news-detail {
    display: block;
    padding: 42px 30px 50px 41px;
    width: 464px;
  }

  .news .news-list {
    background-color: #e1e1e1;
    width: 275px;
    padding: 14px 0 0 0px;
  }

  .news .news-item.active,
  .news .news-item:hover {
    background-color: #adadad;
  }

  .news .news-mb-trigger {
    display: none;
  }

  .news .news-item-header {
    padding-right: 0;
  }

  .news .news-mb-content {
    display: none !important;
  }
}
