.light-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  z-index: 999;
  opacity: 0;
  transition: all 0.4s;
  overflow: auto;
}

.light-box.active {
  opacity: 1;
  visibility: visible;
}

.light-box .lb-content {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px 0;
}

.light-box .lb-close {
  position: fixed;
  cursor: pointer;
  width: 23px;
  height: 23px;
  top: 20px;
  right: 20px;
  z-index: 10;
  color: #fff;
}
