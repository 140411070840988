.cpps-blue-title {
  font-size: 12px;
  color: #003366;
  font-weight: bold;
  margin-bottom: 10px;
}

.cpps-blue-title a {
  font-size: 12px;
  color: #003366;
  font-weight: bold;
  text-decoration: none;
}

.cpps-grey-title {
  font-size: 12px;
  color: #666;
  font-weight: bold;
  margin-bottom: 10px;
}

.cpps-grey-title a {
  font-size: 12px;
  color: #666;
  font-weight: bold;
  text-decoration: none;
}

.cpps-content {
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
  line-height: 1.3;
}

.ui-header .ui-title,
.ui-footer .ui-title {
  display: none !important;
}

.cpps-list-20 {
  padding-left: 20px;
  list-style-type: square;
}

.cpps-link {
  text-decoration: underline;
  color: #003366;
}
