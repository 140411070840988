.website-menu {
  position: relative;
  width: 221px;
  height: 100%;
  padding-top: 0;
  background-color: #fff;
  overflow-y: unset;
  text-align: right;
  padding-right: 38px;
  display: none;
}

.website-menu.active {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.website-menu .wm-main-link {
  color: #00255d;
  font-size: 16px;
  display: block;
}

.website-menu .wm-sub-link {
  text-align: center;
  font-size: 13px;
  line-height: 30px;
  color: #666;
}

.website-menu .wm-sub > li.active .wm-sub-link {
  display: block;
  background-color: #666;
  color: #fff;
}

.website-menu .wm-main > li.active .wm-main-link {
  background-color: #00255d;
  color: #fff;
}

.website-menu .wm-main > li {
  margin-bottom: 12px;
}

.website-menu .wm-slogan {
  display: none;
}

.website-menu ul {
  list-style: none;
  padding: 0;
}

@media (min-width: 960px) {
  .website-menu {
    display: block;
  }
  .website-menu .wm-slogan {
    display: block;
    margin-bottom: 34px;
  }

  .website-menu .wm-sub > li.active .wm-sub-link {
    display: inline-block;
    text-align: right;
    background-color: #fff;
    color: #001c54;
  }

  .website-menu .wm-main-link {
    display: inline-block;
    padding: 2px 0;
    color: #333;
    font-size: 12px;
    background-color: #fff;
    padding-left: 5px;
  }

  .website-menu .wm-sub-link {
    display: inline-block;

    text-align: left;
    font-size: 12px;
    line-height: unset;

    padding: 0;
    color: #333;
    background-color: #fff;
    padding-left: 5px;
  }

  .website-menu .wm-sub {
    background-color: #fff;
    display: none;
  }

  .website-menu .wm-main {
    font-size: 12px;
  }

  .website-menu .wm-main > li {
    margin: 10px 0;
  }

  .website-menu .wm-sub > li {
    line-height: 1;
    margin-bottom: 4px;
  }

  .website-menu .wm-main > li.active {
    background-color: #001c54;
  }

  .website-menu .wm-sub > li.active {
    background-color: #333;
  }

  .website-menu .wm-main > li.active .wm-main-link {
    font-weight: bold;
    background-color: #fff;
    color: #001c54;
  }

  .website-menu .wm-main > li.active .wm-sub {
    display: block;
  }
}
