.mobile-menu {
  position: fixed;
  width: 100%;
  height: 0;
  padding-top: 87px;
  background-color: #fff;
  text-align: center;
  overflow-y: auto;
  z-index: 11;
  opacity: 0;
  transition: height 0.4s;
  visibility: hidden;
}

.mobile-menu.active {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.mobile-menu .wm-main-link {
  color: #00255d;
  font-size: 16px;
  display: block;
}

.mobile-menu .wm-sub-link {
  text-align: center;
  font-size: 13px;
  line-height: 30px;
  color: #666;
}

.mobile-menu .wm-sub > li.active .wm-sub-link {
  display: block;
  background-color: #666;
  color: #fff;
}

.mobile-menu .wm-main > li.active .wm-main-link {
  background-color: #00255d;
  color: #fff;
}

.mobile-menu .wm-main > li {
  margin-bottom: 12px;
}

.mobile-menu .wm-slogan {
  display: none;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

@media (min-width: 960px) {
  .mobile-menu {
    display: none;
  }
}
