.subscribe {
  background-color: #d0d0d0;
}

.subscribe .subscribe-form {
  padding: 20px;
}

.subscribe .sub-form-ttl {
  flex: 0 0 100%;
}

.subscribe .sub-form-txt {
  flex: 0 0 100%;
}

.subscribe .sub-btn {
  background-color: #002152;
  color: #fff;
  font-size: 11px;
}

@media (min-width: 960px) {
  .subscribe .sub-form-ttl {
    flex: 0 0 20%;
  }

  .subscribe .sub-form-txt {
    flex: 0 0 80%;
  }
}
