.property-portfolio {
  background-color: #e1e1e1;
}

.property-portfolio .pp-title {
  font-size: 13px;
  color: #041a3b;
  padding-bottom: 14px;
  font-weight: bold;
}

.property-portfolio .pp-address {
  font-size: 12px;
  color: #333333;
  padding-bottom: 12px;
}

.property-portfolio .pp-icon {
  font-size: 18px;
  top: 4px;
  position: relative;
  margin-left: 10px;
}

.property-portfolio .pp-icon-2 {
  position: relative;
  margin-left: 10px;
  display: inline-block;
  width: 17px;
}

.property-portfolio .pp-button {
  background-color: #666;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
}

.property-portfolio .pp-button:hover {
  background-color: #000;
}

.property-portfolio .pp-item {
  background-color: #d7d7d7;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.property-portfolio .pp-item .pp-item-content {
  flex: 1;
  padding-right: 20px;
  order: 2;
}
.property-portfolio .pp-item .pp-item-slider .swiper-container {
  max-width: 768px;
  height: 300px;
}

.property-portfolio .pp-item .pp-item-slider {
  order: 1;
  margin-bottom: 30px;
}

.property-portfolio .swiper-button-prev:after,
.property-portfolio .swiper-button-next:after {
  font-size: 30px;
}

.property-portfolio .swiper-container .pp-item-slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 260px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.property-portfolio .swiper-container .pp-item-slider-control {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  background-color: #9d9d9d;
}

@media (min-width: 960px) {
  .property-portfolio .pp-item {
    padding: 20px 0 20px 40px;
    flex-direction: row;
  }

  .property-portfolio .pp-item .pp-item-slider .swiper-container {
    width: 300px;
  }

  .property-portfolio .pp-item .pp-item-slider {
    order: 2;
  }

  .property-portfolio .pp-item .pp-item-content {
    order: 1;
  }
}
