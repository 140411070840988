body {
  margin: 0;
  padding: 0;
  color: #666666;
  font-size: 11px;
}

body.overflow-y-hidden {
  overflow-y: hidden;
}

.app {
  max-width: 960px;
  background-color: #ffffff;
  margin: auto;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.page-content {
  width: 100%;
  margin-top: 87px;
}

.text-decoration-none {
  text-decoration: none;
}

p {
  font-size: 12px;
  color: #666666;
}

ul {
  font-size: 12px;
  color: #666666;
}

.bg-grey {
  background-color: #d0d0d0;
}

.text-deep-blue {
  color: #041a3b;
}

.text-deep-grey {
  color: #333333;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 960px) {
  body {
    background-color: #bfbfbf;
  }
  .page-content {
    width: calc(100% - 121px);
    margin-top: 0;
  }
}
